import React from 'react'
import {Col, Row} from 'react-materialize'
import author from '../../images/author.png'

const Creator = () => (
    <div className='About__creator'>
        <h3 className='About__title'>About the creator</h3>
        <Row className='About__creator__container'>
            <Col s={12} l={6}>
                <span className='About__creator__img'>
                    <img src={author} alt="author profile"/>
                </span>
                <span className='About__creator__name'>Steve Hook</span>
            </Col>
            <Col s={12} l={6} className='About__creator__info'>
                <p>
                    Hey, my name is Ștefan Cîrlig, however because I decided to teach Go internationally
                    I simply translated it into the English version and it's simply Steve Hook.

                    <br/><br/>

                    I am a self taught engineer with over 3 years of experience in the industry.
                    I started out as a full stack PHP developer, then frontend
                    using JavaScript and then fell in love with Go programming language.

                    I am the Creator of GopherTuts, I do everything from writing the code, drawing the animations,
                    recording and editing the video till the video delivery, so that you guys can have
                    the best experience while watching it and playing with Go

                    <br/><br/>

                    If you haven't already. Please make sure to check out
                    the <a target='_blank' rel="noopener noreferrer" href="https://www.youtube.com/c/GopherTuts">YouTube</a> channel
                    and SUBSCRIBE if you ❤ what I do, also consider submitting
                    a <a target='_blank' rel="noopener noreferrer" href="https://feedback.gophertuts.com">FEEDBACK</a> if
                    you think I can improve it, and finally consider
                    supporting the project
                    on <a target='_blank' rel="noopener noreferrer" href="https://www.patreon.com/gophertuts">Patreon</a> if
                    it's possible for you
                </p>
            </Col>
        </Row>
    </div>
);

export default Creator
